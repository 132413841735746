import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Alert: ComponentStyleConfig = {
    variants: {
        solid: {
            container: {
                bg: 'secondary.500',
            },
        },
    },
};
